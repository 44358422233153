@import "../misc/bootstrap";
@import "../sprite";

.burger {
	background-image: url("../../img/icons/burger.svg");
	width: 26px;
	height: 18px;
	display: block;
}

.menu_burger {
	cursor: pointer;
	position: relative;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.menu-global {
	backface-visibility: hidden;
	position: absolute;
	border-top: 1px solid white;
	width: 24px;
	transition: 0.35s;
	display: flex;
	margin-top: 12px;
	justify-content: center;
}
.menu-top {
	top: 0;
}
.menu-middle {
	top: 8px;
}
.menu-bottom {
	top: 16px;
}


.menu-top-click {
	backface-visibility: hidden;
	top: 8px;
	transform: rotate(50deg);
	transition: 0.35s 0.5s;
}
.menu-middle-click {
	opacity: 0;
}
.menu-bottom-click {
	backface-visibility: hidden;
	top: 8px;
	transform: rotate(-410deg);
	transition: 0.35s 0.5s;
}
