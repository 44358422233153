@import "../misc/bootstrap";
@import "../sprite";

.logo {
	background-image: url("../../img/logo.svg");
	width: 182px;
	height: 50px;
	display: block;
	background-repeat: no-repeat;

	@include media-max(lg){
		width: 153px;
		height: 42px;
		background-image: url("../../img/logo_mobile_2.svg");

	}
}


