@import "../misc/bootstrap";
@import "../sprite";

.menu-ul {
	list-style: none;
	margin: 0 auto;
	text-align: center;
	padding: 0;
	margin-top: 60px;
}

.menu-a {
	font-size: rem(22px);
	color: white;
	line-height: 92%;
	font-weight: 600;

	&:hover {
		color: #e0883c;
		transition: all .2s;
		text-decoration: none;
	}

	&:active {
		color: #e0883c;

	}
}

.menu-li + .menu-li {
	padding-top: 30px;
}

.menu_active {
	color: #e0883c;
}


.menu-language{

	border: 1px solid rgba(194, 194, 194, .41);
	width: 50px;
	height: 33px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 50px auto 0;

	button{

		padding: 0;

		font-weight: 600;
		font-size: 15px;
		color: #fff;
		line-height: 132%;
	}
}

.menu-social {
	display: flex;
	gap: 7px;
	justify-content: center;
	margin-top: 100px;
}