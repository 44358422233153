@import "bootstrap";


h1, .h1 {
	font-weight: bold;
	font-size: clamp(41px, 7vw, 70px);
	line-height: 100%;
}

h2, .h2 {
	font-size: clamp(36px, 4vw, 59px);
	line-height: 117%;
	font-weight: bold;
}

h3, .h3 {
	font-size: clamp(20px, 4vw, 26px);
	line-height: 150%;
	font-weight: bold;
}

h4, .h4 {
	font-weight: normal;
	font-size: clamp(20px, 4vw, 20px);
	line-height: 150%;
	color: #8a8a8a;
}

h5, .h5 {
	font-weight: bold;
	font-size: clamp(22px, 4vw, 24px);
	line-height: 150%;
}


p, .text {
	font-size: clamp(16px, 4vw, 16px);
	line-height: 160%;
	font-style: normal;
}
