@import "../misc/bootstrap";
@import "../sprite";

.headerForm-content {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 5;
  justify-content: space-between;
  height: 110px;

  @include media-max(lg){
    height: 70px;
  }
}

.headerForm-social{
  display: flex;
  gap: 7px;
}

.headerForm-right {
  display: flex;
  align-items: center;
  gap: 40px;
}

.formLogin-flex {
  margin-top: 70px;
}

.formLogin{
  width: 100%;
  max-width: 435px;
  margin: 0 auto;

  @media (max-width: 480px){

    max-width: 390px;
  }

  @media (max-width: 400px){

    max-width: 360px;

  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    font-weight: 500;
    font-size: 29px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;

    @media (max-width: 480px){
      font-size: 24px;
    }
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    background: #004663;
    border: none;
    border-radius: 0;
    padding: 20px 0;
    line-height: 1.2;
    width: 100%;
  }



  .nav-link{
    font-weight: 500;
    font-size: 29px;
    text-transform: uppercase;
    text-align: center;
    color: rgba(255, 255, 255, 0.33);

    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    padding: 20px 0;
    line-height: 1.2;

    @media (max-width: 480px){
      font-size: 24px;


    }
  }

  .nav-tabs .nav-item:nth-of-type(1)  {

    width: 40%;
    display: flex;
    justify-content: center;



  }

  .nav-tabs .nav-item:last-child {
    width: 60%;
    display: flex;
    justify-content: center;

  }

  .nav-tabs {
    border-bottom: 1px solid #004663;
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  div#pills-tabContent {
    background: #004663;
    padding: 25px 20px 40px;

    @media (max-width: 400px){

      padding: 25px 20px 40px;
    }


  }



  .form-group{
    margin-bottom: 35px;
  }

  .nav-tabs .nav-item:last-child .nav-link.active{
    position: relative;

  }

}

.input-container {
  position: relative;
  width: 100%;
}

.input {
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid rgba(white,0.35);
  color: #fff;
  font-size: rem(16px);
  outline: 0;
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
  padding: 0 0 10px 10px;
}


.placeholder {
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.05em;
  color: rgba(white, .35);
}


.btn-sign{
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  text-transform: uppercase;
  color: #fff;
  background: #e0883c;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  border: 1px solid #e0883c;
  width: 100%;

  &:hover{
    background: transparent;
    text-decoration: none;
    transition: all .2s;
    color: white;
  }

}

.formLogin-button {
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 15px;

  @media (max-width: 480px){
    flex-direction: column;
    margin-top: 0;
  }

  button{
    width: 100%;
  }
}

.btn-pass{
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  letter-spacing: 0.05em;
  color: #fff;
  background: #033f58;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  border: 1px solid #033f58;
  width: 100%;

  &:hover{
    background: transparent;
    text-decoration: none;
    transition: all .2s;
    color: white;
  }
}

.formLogin-sign{
  margin-top: 35px;
  text-align: center;

  a{
    display: block;

    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.05em;
    color: #e0883c;
    text-decoration: underline;

    &:hover{
      text-decoration: none;
    }
  }
}

.password-visible{
  background-image: url("../../img/icons/eye.svg") !important;
  width: 15px;
  height: 11px;
  display: block;
  position: absolute !important;
  right: 0 !important;
  top: 30px !important;
}

.password-visible2{
  background-image: url("../../img/icons/eye.svg");
  width: 15px;
  height: 11px;
  display: block;
  position: absolute;
  right: 0;
  top: 30px;
}

.password-visible3{
  background-image: url("../../img/icons/eye.svg");
  width: 15px;
  height: 11px;
  display: block;
  position: absolute;
  right: 0;
  top: 30px;
}

.password-visible4{
  background-image: url("../../img/icons/eye.svg") !important;
  width: 15px;
  height: 11px;
  display: block;
  position: absolute !important;
  right: 15px !important;
  top: 45px !important;
  background-color: transparent !important;
}

.password-visible5{
  background-image: url("../../img/icons/eye.svg") !important;
  width: 15px;
  height: 11px;
  display: block;
  position: absolute !important;
  right: 15px !important;
  top: 45px !important;
  background-color: transparent !important;
}

.option-form{
  color: black;
}

.select-label{
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.05em;
  color: rgba(white, .35);

}