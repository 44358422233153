@import "../misc/bootstrap";
@import "../sprite";

.customModal_2{
  position: relative;
  height:  auto;

  .modal-dialog{
    max-width: 511px;
    margin: 1.75rem auto;

    @include media-max(md){
      max-width: 350px;
    }
  }

  .modal-content{
    background:  #074966;
    padding: 60px 55px 90px 55px;
    border: none;
    border-radius: 0;

    @include media-max(md){
      padding: 60px 25px;
    }
  }

  .modal-content_2{
    padding: 55px 30px 45px 35px;

    @include media-max(md){
      padding: 40px 25px;
    }
  }

  .modal-title{
    h3{
      margin: 0;
      font-weight: 500;
      font-size: 36px;
      text-transform: uppercase;
      text-align: center;
      color: #fff;

    }
  }

  .modal-form{
    margin-top: 45px;
  }

  .modal-select {
    display: flex;
    flex-direction: column;

    label{
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 14px;
      color: #fff;
      padding-left: 27px;
    }
  }

  .form-control{
    border-radius: 0;
    background: #034360;
    border: 1px solid rgba(255, 255, 255, 0.29);
    height: 52px;
    font-weight: 600;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.57);
    padding-left: 27px;
    appearance: none;
    position: relative;

    &::placeholder{
      font-weight: 600;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.57);
    }



    &:focus{
      outline: none;
      box-shadow: none;
    }
  }

  .modal-selectBlock{
    position: relative;

    &::before{
      content: "";
      background-image: url("../../img/icons/select-arrow.svg");
      width: 13px;
      height: 14px;
      display: block;
      background-repeat: no-repeat;
      position: absolute;
      right: 15px;
      top: 18px;
      z-index: 1;
    }
  }

  .form-group{
    margin-bottom: 30px;
  }

  .btn-continue{
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e0883c;
    border: 1px solid #e0883c;
    height: 62px;
    width: 192px;

    @include media-max(md){
      width: 100%;
    }

    &:hover{
      transition: all .2s;
      background: transparent;
      text-decoration: none;
    }
  }

  .btn-support{
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #074966;
    border: 1px solid #e0883c;
    height: 62px;
    width: 192px;

    @include media-max(md){
      width: 100%;
    }

    &:hover{
      transition: all .2s;
      background: #e0883c;
      text-decoration: none;
    }
  }

  .modal-body{
    padding: 0;
  }

  .modal-button{
    display: flex;
    justify-content: space-between;

    @include media-max(md){
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      gap: 20px;
    }

    button{
      @include media-max(md){
        width: 100%;
      }
    }
  }

  .modal-qr{
    margin-top: 30px;
    display: flex;
    align-items: flex-end;
    width: 100%;
    gap: 25px;

    @include media-max(md){
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .modal-qr_2{
    margin-top: 30px;
  }

  .modal-qr_left{
    img{
      width: 100%;
      object-fit: cover;
      min-height: auto;

    }
  }

  .modal-qr_form{
    margin-top: 25px;
  }

  .modal-qr_right{
    width: 100%;
  }

  .modal-qr_left {
    width: 100%;
    max-width: 102px;
  }

  .modal-qr_form .form-control{
    padding-right: 35px;

    @include media-max(md){
      padding-right: 0;
    }

    &::placeholder{
      font-size: 14px !important;
    }
  }

  .modal-qr_form .form-group{
    margin-bottom: 0 !important;
  }

  .modal-qr_hash{
    margin-top: 40px;

    @include media-max(md){
      margin-top: 20px;
    }
  }

  .btn-hash{
    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: center;
    background: #e0883c;
    border: 1px solid #e0883c;
    height: 62px;
    width: 100%;
    color: white;

    &:hover{
      transition: all .2s;
      background: transparent;
      text-decoration: none;
    }
  }

  .modal-button_2 button{
    width: 100%;
  }

  .modal-qr_right .totalInfo-left_block{
    @include media-max(md){
      justify-content: center;
    }
  }

  .btn-continue_1{
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e0883c;
    border: 1px solid #e0883c;
    height: 62px;
    width: 215px;

    @include media-max(md){
      width: 100%;
    }

    &:hover{
      transition: all .2s;
      background: transparent;
      text-decoration: none;
    }
  }

  .btn-support_1{
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #074966;
    border: 1px solid #e0883c;
    height: 62px;
    width: 215px;

    @include media-max(md){
      width: 100%;
    }

    &:hover{
      transition: all .2s;
      background: #e0883c;
      text-decoration: none;
    }
  }

  .modal-text_base{
    h3{
      font-weight: 600;
      font-size: 21px;
      color: #fff;
    }

    p{
      font-weight: 500;
      font-size: 16px;
      color: #fff;
    }
  }

}

