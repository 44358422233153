@import "misc/bootstrap";
@import "misc/bootstrap-init";
@import "misc/button";
@import "misc/text";
@import "misc/animation";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');

html, body {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	padding: 0;
	margin: 0;
	font-family: "Montserrat", sans-serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
	font-size: $baseFontSize;
	line-height: 1.3;
	background: #003a52;
	color: white;
}

* {
	box-sizing: border-box;
}

*, *:active, *:focus {
	outline: 0 !important;
	-moz-outline-style: none !important;
}

a[href^="tel"] {
	text-decoration: none;
	color: inherit;
	white-space: nowrap;
}

.noScroll {
	overflow: hidden;
}

@import "block/page";
@import "block/header";
@import "block/footer";
@import "block/burger";
@import "block/close";
@import "block/content";
@import "block/copyright";
@import "block/desktopMenu";
@import "block/icon";
@import "block/logo";
@import "block/menu";
@import "block/mobileMenu";
@import "block/sideBar";
@import "block/preloader";
@import "block/highcharts";
@import "block/banner";
@import "block/figure";
@import "block/formLogin";
@import "block/verification";
@import "block/works";
@import "block/rolling";
@import "block/accordionBlock";
@import "block/connection";
@import "block/slider";
@import "block/advantages";
@import "block/video";
@import "block/tools";
@import "block/contacts";
@import "block/history";
@import "block/questions";
@import "block/tokenization";
@import "block/doing";
@import "block/plan";
@import "block/documentation";
@import "block/affiliate";
@import "block/account";
@import "block/setting";
@import "block/tabs";
@import "block/tabs-3";
@import "block/tabs-2";
@import "block/tabs-1";
@import "block/profile";
@import "block/customModal";
@import "block/customModal_2";
@import "block/customModal_3";
@import "block/customModal_4";
@import "block/customModal_5";
@import "block/select";






