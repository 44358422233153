@import "../misc/bootstrap";
@import "../sprite";
.close {
	background-image: url("../../img/icons/close-modal.svg");
	width: 27px;
	height: 27px;
	display: block;
	z-index: 1;
	position: relative;
}

.modal-close {
	position: absolute;
	top: 10px;
	right: 10px;
	left: 0;
	bottom: 0;
	display: block;
}