@import "../misc/bootstrap";
@import "../sprite";


.sideBar {
	position: fixed;
	top: 70px;
	left: 0;
	transform: translateX(-100%);
	height: 100%;
	width: 100%;
	margin: 0;
	z-index: 999;
	transition: .2s;
	padding-bottom: 80px;
}

.sideBar-content {
	width: 94%;
	max-width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: #004662;
	position: relative;
	margin: 0 auto;
}


.sideBar_visible {
	transform: translateX(0) !important;
}

.sideBar-close {
	margin-top: 20px;
	position: relative;
	overflow: hidden;
}

.sideBar-right {
	position: fixed;
	top: 70px;
	right: 0;
	transform: translateX(100%);
	height: 100%;
	width: 100%;
	margin: 0;
	z-index: 999;
	transition: .2s;
	padding-bottom: 80px;
}

.sideBar-content_right {
	width: 100%;
	max-width: 325px;
	height: 100%;
	overflow: auto;
	background-color: #003a52;
	box-shadow: -10px 10px 28px 0 rgba(0, 0, 0, 0.25);
	position: absolute;
	margin: 0 !important;
	padding: 0 20px;
	right: 0;
}

.sideBar2 {
	position: fixed;
	top: 70px;
	left: 0;
	transform: translateX(-100%);
	height: 100%;
	width: 100%;
	margin: 0;
	z-index: 1060;
	transition: .2s;
	padding-bottom: 80px;
}

.sideBar-content2 {
	width: 94%;
	max-width: 264px;
	height: 100%;
	overflow: auto;
	background-color: #004662;
	position: relative;
	padding: 30px 15px;
}