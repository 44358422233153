@import "../misc/bootstrap";
@import "../sprite";


.copyright {
	border-top: 1px solid rgba(white, 0.14);
	padding: 30px 0;

	@include media-max(md){
		border: none;
		padding: 60px 0 35px 0;
	}
}

.copyright-content {
	display: flex;
	align-items: baseline;
	justify-content: space-between;

	@include media-max(md){
		flex-direction: column-reverse;
		justify-content: flex-start;
		align-items: center;
		gap: 30px;
	}
}

.copyright-text{
	p{
		margin: 0;
		font-weight: 400;
		font-size: rem(15px);
		color: rgba(255, 255, 255, 0.34);
	}
}

.copyright-link {
	a{
		font-weight: 400;
		font-size: rem(15px);
		line-height: 107%;
		color: rgba(255, 255, 255, 0.34);
	}
}

