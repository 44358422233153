@import "../misc/bootstrap";
@import "../sprite";

.connection{
  position: relative;
  min-height: 658px;
  padding: 120px 0 130px 0;

  @include media-max(md){
    padding: 65px 0 140px 0;
  }

  &::before{
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("../../img/banner-slider.png");
    background-repeat: no-repeat;
    display: block;
    opacity: .3;
    background-size: cover;
    background-position: right;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    @include media-max(sm){
      background-position: center right 30%;
    }

  }
}

.connection-content {

}

.connection-title{

  width: 100%;
  max-width: 450px;

  h2{
    margin: 0;

    font-weight: 500;
    line-height: 117%;
    text-transform: uppercase;
    color: #fff;

    span{
      color: #e0883c;
    }
  }
}

.connection-slider{
  margin-top: 70px;
  width: 100vw;
  position: relative;

  @include media-max(md){
    margin-top: 40px;
  }
}

.connection-item{
  background: #003a52;
  padding: 30px 18px;

  @include media-max(lg){
    padding: 25px 15px;
  }
}

.connection-slider .slick-slide {
  margin-right: 30px;
  width: 490px !important;

  @include media-max(lg){
    width: 213px !important;
    margin-right: 11px;
  }
}

.connection-item_currency {

  padding-top: 20px;

  span{
    display: inline;
    font-weight: 500;
    font-size: 15px;
    line-height: 158%;
    letter-spacing: 0.07em;
    color: #e0883c;

    background: #00425c;
    padding: 5px;
    text-transform: uppercase;
  }
}

.connection-item_text{

  p{
    margin: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 158%;
    letter-spacing: 0.07em;
    color: rgba(255, 255, 255, 0.41);
  }
}

.connection-item_arrow1{
  background-image: url("../../img/icons/arrow_c1.svg");
  width: 57px;
  height: 8px;
  display: block;
  margin: 0 auto;
}

.connection-item_arrow2{
  background-image: url("../../img/icons/arrow_c2.svg");
  width: 56px;
  height: 9px;
  display: block;
  margin: 0 auto;
}

.connection-item_block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;

  @include media-max(lg){
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 25px;
  }
}

.connection-item_1 {
  text-align: center;
}

.connection-item_3 {
  text-align: center;
}

.connection-item_text {
  padding: 15px 0;

  @include media-max(lg){
    padding: 10px 0;
  }
}

.connection-item_2 {
  text-align: center;
  position: relative;
  left: -30px;

  @include media-max(lg){
    left: 0;
  }
}