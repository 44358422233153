@import "../misc/bootstrap";
@import "../sprite";

.tokenization{
  position: relative;
  height: auto;

  padding: 120px 0 100px 0;

  @include media-max(md){
    padding: 85px 0;
  }
}

.tokenization-content {

  width: 100%;
}

.tokenization-text{

  margin-top: 25px;
  width: 100%;
  max-width: 493px;

   p{
     margin: 0;
     font-weight: 300;
     font-size: 16px;
     color: rgba(255, 255, 255, 0.7);

   }
}

.tokenization-item{
  border: 1px solid #006a91;
  height: 292px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  text-align: center;

  padding: 0 50px;

  @include media-max(sm){
    height: 312px;
  }
}

.tokenizationText{
  position: relative;
  height: auto;

  padding: 120px 0 150px 0;

  @include media-max(md){
    padding: 80px 0 50px 0;
  }

  &::before{
    content: "";
    background-image: url("../../img/bg-token.png");

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    display: block;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

.tokenizationText-title{

  width: 100%;
  max-width: 450px;

  h2{
    margin: 0;
    font-weight: 500;
    text-transform: uppercase;
    color: #fff;

    span{
      color: #e0883c;
    }
  }
}

.tokenizationText-description{
  margin-top: 50px;

  @include media-max(md){
    margin-top: 30px;
  }

  p{
    font-weight: 300;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
  }
}