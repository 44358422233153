@import "../misc/bootstrap";
@import "../sprite";

.content {
	max-width: 1230px;
	width: 94%;
	position: relative;
	margin: 0 auto;
	height: 100%;
}
